import React, { FC ,useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import AppInput from '../appInput/appInput';
import TablePagination from '@material-ui/core/TablePagination';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AppIcon from '../appIcon/appIcon';
import { doubleLeft, doubleRight,angleLeft, angleRight} from '../../theme/icons';
import { DEFAULT_PAGE_SIZES } from './appPaginationConstants'
import "./appPagination.scss";

interface Iprops {
  maxCount?: number;
  onChangePage?: Function;
  pageNumber?: number;
  pageSize?: number;
  currentPage?: number;
  maxPage?: number;
  onChangeRowsPerPage?: Function;
  onChangePage?: (page: number) => void;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
}

const AppPagination: FC<Iprops> = ({ maxCount = 0, pageSize = 50, currentPage = 0, maxPage = 0, pageNumber: initialPageNumber = 1, onChangePage, onChangeRowsPerPage }) => {
  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  const handleChangePage = (newPage) => {
    onChangePage && onChangePage(newPage)
  };

  const handleChangeRowsPerPage = ({ target }) => {
    onChangeRowsPerPage && onChangeRowsPerPage(parseInt(target.value))
  };

  const handleFirstPageButtonClick = () => {
    handleChangePage(1);
    setPageNumber(1);
  };

  const handleBackButtonClick = () => {
    handleChangePage(pageNumber - 1);
    setPageNumber(pageNumber - 1);
  };

  const handleNextButtonClick = () => {
    handleChangePage(pageNumber + 1);
    setPageNumber(pageNumber + 1);
  };

  const handleLastPageButtonClick = () => {
    handleChangePage(maxPage);
    setPageNumber(maxPage);
  };
  const handlePageNumberChange = (event) => {
    if (event && event.target) {
      const value = parseInt(event.target.value, 10);
      if (!isNaN(value)) {
        setPageNumber(value);
      } else {
        setPageNumber(null);
      }
    }
  };
  
  const handlePageSearch = (event) => {
    if (event.keyCode === 13 || event.key === 'Enter')  {
      handleChangePage(pageNumber);
    }
  };
  const renderArrows = () => {
    return (
      <div className="app-pagination-buttons">
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={pageNumber === 1}
        >
          <AppIcon icon={doubleLeft} />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={pageNumber === 1}
        >
         <AppIcon icon={angleLeft} />
        </IconButton>
        <AppInput type="text" value={pageNumber > 0 ? pageNumber : ''} onChange={handlePageNumberChange} onKeyPress={handlePageSearch}/>
        <span className="page-count"> 
          {`/ ${maxPage}`}
        </span>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={pageNumber >= maxPage}
        >
          <AppIcon icon={angleRight} />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={pageNumber >= maxPage}
        >
          
          <AppIcon icon={doubleRight} />
        </IconButton>
      </div>)
  }
  const getNearestPageSize = (pageSize: number, maxCount: number) => {
    const pageSizeValues = DEFAULT_PAGE_SIZES.map(option => option.value);
    return pageSizeValues.reduce((prev, curr) => {
      return (curr <= maxCount && Math.abs(curr - pageSize) < Math.abs(prev - pageSize)) ? curr : prev;
    }, pageSizeValues[0]);
  };
  return (
    <div className="app-pagination">
      <TablePagination
        component="div"
        count={maxCount}
        page={Math.max(pageNumber - 1, 0)}
        rowsPerPageOptions={DEFAULT_PAGE_SIZES}
        rowsPerPage={getNearestPageSize(pageSize,maxCount)}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        ActionsComponent={renderArrows}
        labelRowsPerPage=""
        SelectProps={{IconComponent:ExpandMore}}
      />
    </div>
  );
}

export default AppPagination;
